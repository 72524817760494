import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Config from '../../Config';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { toast, ToastContainer } from 'react-toastify';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LogarithmicScale,  // Register LogarithmicScale
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import BICoin from '../../assets/images/coin.svg';
import { ArrowDownIcon, ArrowUpIcon, CalendarDaysIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { NumberFormat6D } from './General/Functions';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { Bar } from 'react-chartjs-2';
import { Spinner } from 'react-bootstrap';
import LoginCheck from './General/LoginCheck';
// Register necessary components, including LogarithmicScale
ChartJS.register(CategoryScale, LinearScale, BarElement, LogarithmicScale, LineElement, PointElement, Title, Tooltip, Legend);



const BICView = () => {

  const AccessKey = localStorage.getItem('AccessKey');
  const UserID = localStorage.getItem('ID');
  const [loading, setLoading] = useState(false);

  const [PageData, setPageData] = useState(0);
  const [startRate, setstartRate] = useState(0);
  const [coinBuyingRate, setcoinBuyingRate] = useState(1);
  const [coinSellingRate, setcoinSellingRate] = useState(1);
  const [currentCoinBalance, setcurrentCoinBalance] = useState(1);
  const [Day, setDay] = useState('3');

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey,
      UserID,
      GraphType: Day,
    };
    // console.log(data)
    const api_config = {
      method: 'post',
      url: `${Config.base_url}CoinDetail/GetCoinViewData`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    };

    axios(api_config)
      .then((response) => {
        // console.log(response.data)
        if (response.data.status_code === 1) {
          setPageData(response.data)
          setstartRate(response.data.startRate);
          setcoinBuyingRate(response.data.coinBuyingRate);
          setcoinSellingRate(response.data.coinSellingRate);
          setcurrentCoinBalance(response.data.currentCoinBalance);

          setLoading(false);
        } else if (response.data.status_message === 'Invalid Access Key.') {
          navigate('/login');
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error('Error fetching coin rate', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setLoading(false);
      });
  }, [Day]);



  const Linegraphoptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Coin Rate Progess',
        color: '#FFBE18',
        font: {
          size: 14,
          weight: '400',
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },

    scales: {
      x: {
        display: true,
        ticks: {
          color: '#FFBE18',
        },
        grid: {
          color: '#FFBE18',
        },
      },
      y: {
        display: false,
        type: 'logarithmic',
        beginAtZero: false,

        ticks: {
          color: '#FFBE18',
          min: 1,
        },
        grid: {
          color: '#FFBE18',
        },
      },
    },
  };
  const Bargraphoptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Coin Quantity Progress',
        color: '#45F882',
        font: {
          size: 14,
          weight: '400',
        },

      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          color: '#45F882',
        },
        grid: {
          color: '#898989',
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        ticks: {
          color: '#45F882',
        },
        grid: {
          color: '#373737',
        },
      },
    },
  };


  return (
    <>
      <LoginCheck />
      <ToastContainer />
      <div className="mobile-container">
        <h3 className="section-title">BIC Trading Summary</h3>
        {
          loading ?
            <Spinner animation="border" role="status" className="full-screen-loading">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            :
            <>

              <ul className='text-white bic-view-list'>
                <li>
                  <NavLink to="/dashboard/transactions/coin-transactions-detail?activemenu=BIC%20Coins"></NavLink>
                  <div className="left">
                    <div className="icon">
                      <img src={BICoin} alt="Coin" />
                    </div>
                    <div className="content">
                      <strong>Coin Balance</strong>
                      <span>{NumberFormat6D(currentCoinBalance)}</span>
                    </div>
                  </div>
                  <div className="right">
                    <ChevronRightIcon />
                  </div>
                </li>
                <li>
                  <NavLink to="/dashboard/coin-buy"></NavLink>
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="39" viewBox="0 0 45 39" fill="none">
                      <path d="M1.63281 32.0181C13.0019 32.0089 24.3215 33.5144 35.293 36.4949C36.8421 36.9168 38.389 35.7662 38.389 34.1596V32.0181M4.829 1.6543V3.25239C4.829 3.67623 4.66063 4.08272 4.36093 4.38242C4.06123 4.68212 3.65475 4.85049 3.23091 4.85049H1.63281M1.63281 4.85049V4.05144C1.63281 2.72822 2.70673 1.6543 4.02996 1.6543H39.9871M1.63281 4.85049V24.0276M39.9871 1.6543V3.25239C39.9871 4.13454 40.7031 4.85049 41.5852 4.85049H43.1833M39.9871 1.6543H40.7862C42.1094 1.6543 43.1833 2.72822 43.1833 4.05144V24.8267C43.1833 26.1499 42.1094 27.2238 40.7862 27.2238H39.9871M1.63281 24.0276V24.8267C1.63281 25.4624 1.88537 26.0722 2.33492 26.5217C2.78447 26.9713 3.39419 27.2238 4.02996 27.2238H4.829M1.63281 24.0276H3.23091C3.65475 24.0276 4.06123 24.196 4.36093 24.4957C4.66063 24.7954 4.829 25.2019 4.829 25.6257V27.2238M39.9871 27.2238V25.6257C39.9871 25.2019 40.1555 24.7954 40.4552 24.4957C40.7549 24.196 41.1614 24.0276 41.5852 24.0276H43.1833M39.9871 27.2238H4.829M28.8004 14.4391C28.8004 16.1344 28.127 17.7604 26.9282 18.9592C25.7294 20.158 24.1034 20.8314 22.4081 20.8314C20.7127 20.8314 19.0868 20.158 17.888 18.9592C16.6892 17.7604 16.0157 16.1344 16.0157 14.4391C16.0157 12.7437 16.6892 11.1178 17.888 9.91897C19.0868 8.72016 20.7127 8.04668 22.4081 8.04668C24.1034 8.04668 25.7294 8.72016 26.9282 9.91897C28.127 11.1178 28.8004 12.7437 28.8004 14.4391ZM35.1928 14.4391H35.2099V14.4561H35.1928V14.4391ZM9.62329 14.4391H9.64034V14.4561H9.62329V14.4391Z" stroke="url(#paint0_linear_1262_1398)" stroke-width="3.19619" stroke-linecap="round" stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_1262_1398" x1="22.4081" y1="1.6543" x2="22.4081" y2="36.5827" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FF9813" />
                          <stop offset="1" stop-color="#FFF559" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <strong>Start Rate</strong>
                  <span>{NumberFormat6D(startRate)}</span>
                </li>

                <li>
                  <NavLink to="/dashboard/coin-sell"></NavLink>
                  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="49" viewBox="0 0 43 49" fill="none">
                    <g filter="url(#filter0_d_1262_1409)">
                      <path d="M34.9709 16.3761L21.5899 2.99512M21.5899 2.99512L8.20898 16.3761M21.5899 2.99512V35.1094" stroke="url(#paint0_linear_1262_1409)" stroke-width="4.14377" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                    </g>
                    <defs>
                      <filter id="filter0_d_1262_1409" x="0.611685" y="0.923828" width="41.9563" height="47.3079" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="5.52503" />
                        <feGaussianBlur stdDeviation="2.76252" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1262_1409" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1262_1409" result="shape" />
                      </filter>
                      <linearGradient id="paint0_linear_1262_1409" x1="21.5899" y1="2.99512" x2="21.5899" y2="35.1094" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF1962" />
                        <stop offset="1" stop-color="#F9982D" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <strong>Sell</strong>
                  <span>{NumberFormat6D(coinSellingRate)}</span>
                </li>
                <li>
                  <NavLink to="/dashboard/coin-buy"></NavLink>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="36" viewBox="0 0 30 36" fill="none">
                    <path d="M28.3811 20.7933L15.0011 34.1725M15.0011 34.1725L1.62109 20.7933M15.0011 34.1725V2.0625" stroke="url(#paint0_linear_1262_1403)" stroke-width="3.19619" stroke-linecap="round" stroke-linejoin="round" />
                    <defs>
                      <linearGradient id="paint0_linear_1262_1403" x1="15.0011" y1="2.0625" x2="15.0011" y2="34.1725" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4AC7FF" />
                        <stop offset="1" stop-color="#EE92FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <strong>Buy</strong>
                  <span>{NumberFormat6D(coinBuyingRate)}</span>
                </li>

              </ul>
              <div className="dash-header mt-4">
                <div className="menu">
                  <ul className="menu-link">
                    <li><a className={Day == "4" ? "active" : ""} onClick={(e) => setDay("4", e)}>Today</a></li>
                    <li><a className={Day == "3" ? "active" : ""} onClick={(e) => setDay("3", e)}>Week</a></li>
                    <li><a className={Day == "2" ? "active" : ""} onClick={(e) => setDay("2", e)}>Last 15 Days</a></li>
                    <li><a className={Day == "1" ? "active" : ""} onClick={(e) => setDay("1", e)}>Last Month</a></li>
                    {/* <li>
                <button className="" onClick={(e) => {
                  const picker1 = document.getElementById("header-date");
                  picker1.showPicker();
                }}>
                  <CalendarDaysIcon />
                </button>
                <input
                  type="date"
                  id="header-date"
                  name="header-date"
                  className="menu-date"
                  placeholder="DD/MM/YY"
                />
              </li> */}
                  </ul>

                </div>
              </div>
              <div className="bar-background">
                <Bar
                  data={{
                    labels: PageData.ListofLabels,
                    datasets: [
                      {
                        label: true,
                        data: PageData.ListofValues,
                        borderColor: '#45F882',
                        backgroundColor: '#45F882',
                        fill: false,
                        borderWidth: 2,
                        borderRadius: 10,
                        barPercentage: 0.8,
                        categoryPercentage: 0.5,
                      },
                    ],
                  }}
                  options={Bargraphoptions}
                />
              </div>
              <div className="bar-background mt-3">
                <Line
                  data={{
                    labels: PageData.ListofLabels2,
                    datasets: [
                      {
                        label: true,
                        data: PageData.ListofValues2,
                        borderColor: '#FFBE18',
                        backgroundColor: '#FFBE18',
                        fill: false,
                        borderWidth: 2,
                        pointRadius: 4,
                      },
                    ],
                  }}
                  options={Linegraphoptions}
                />
              </div>
            </>
        }
      </div>
    </>
  );
};

export default BICView;
