import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import config from '../../Config';
import axios from 'axios';
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeIcon, EyeSlashIcon, } from '@heroicons/react/24/outline';
import dividerH from '../../assets/images/divider-h.svg';
import { LockClosedIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Logo from '../../assets/images/logo/gif-logo.gif';
import ReCAPTCHA from 'react-google-recaptcha';
const Login = () => {


  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let navigate = useNavigate();


  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }


  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Throttle function to prevent multiple submissions
  const throttle = (func, delay) => {
    let lastCall = 0;
    return function (...args) {
      const now = new Date().getTime();
      if (now - lastCall >= delay) {
        lastCall = now;
        return func(...args);
      }
    };
  };


  const handleSubmit = throttle ((event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      if (captchaValue) {

        setLoading(true);
        const signInData = {
          Email_Address: event.target.Email_Address.value,
          Password: event.target.Password.value,
        };

        var api_config = {
          method: "post",
          url: config.base_url + "Members/SignIn",
          headers: {
            "Content-Type": "application/json",
          },
          data: signInData,
        };
        axios(api_config)
          .then(function (response) {
            // console.log(response.data);
            if (response.data.status_code == 1) {
              localStorage.setItem("AccessKey", response.data.AccessKey);
              localStorage.setItem("ID", response.data.ID);
              localStorage.setItem("Member_Type", response.data.Member_Type);
              localStorage.setItem("Full_Name", response.data.Full_Name);
              localStorage.setItem("Email_Address", response.data.Email_Address);
              localStorage.setItem("Img_File", response.data.Img_File);
              localStorage.setItem("User_Name", response.data.User_Name);
              localStorage.setItem("Mobile_No", response.data.Mobile_No);
              localStorage.setItem("Current_Balance", response.data.Current_Balance);
              localStorage.setItem("theme", "light");
              toast.success("You are Logged in..Wait..", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
              });

              navigate("/dashboard/dashboard");
              window.scrollTo(0, 0);

            } else {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Network Error..", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000
            });
          });
      } else {
        alert("Please complete the CAPTCHA");
      }
    }
    setValidated(true);
  }, 2000);


  return (
    <>
      <ToastContainer />
      <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="login_box form-default">
            <div className="login_box_head">
              <img src={Logo} alt='brand' />
              <h4>Login to continue</h4>
            </div>
            <div className="login_box_body">
              <div className="icon-input mb-4">
                <input
                  type="text"
                  name="Email_Address"
                  placeholder='Email Address'
                  className="form-control"
                  required
                />
                <div className="icon">
                  <UserCircleIcon />
                </div>
              </div>
              <div className="icon-input mb-4">
                <input
                  type={showPassword ? "text" : "password"}
                  name="Password"
                  placeholder='Password'
                  className="form-control"
                  required
                />
                <div className="icon">
                  <LockClosedIcon />
                </div>
                <div className="toggle-password" onClick={togglePasswordVisibility} >
                  {
                    showPassword ?
                      <EyeSlashIcon />
                      :
                      <EyeIcon />
                  }
                </div>
              </div>
              <div className="mb-4">
                <ReCAPTCHA
                  theme="dark"
                  sitekey="6LeSRSIqAAAAAFIQTB2ZfkEMeo6BQROgLLUyw4xB"
                  onChange={handleCaptchaChange}
                />
              </div>
              <button type="submit" className="button-3d-primary w-100"
                disabled={loading || captchaValue == null}>
                Login {loading ? '...' : ''}
              </button>
            </div>
            <div style={{ marginTop: "-15px" }}>
              <img src={dividerH} />
            </div>
            <div className='text-center mt-3 mb-0'>
              <Link className='link' to={`/forgot-password`}>Forgot Password ?</Link>
            </div>
            <div className='text-center mt-3'>
              <p>Don't have an account?</p>
              <NavLink to={'/signup'} className='mt-3 d-flex m-auto justify-content-center'>SIGN UP</NavLink>
            </div>
          </div>

        </Form>

      </main>


    </>
  );
};

export default Login;
