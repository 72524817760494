import React, { useState, useEffect } from "react";
import TransactionMenu from './TransactionMenu';
import axios from "axios";
import config from "../../../Config";
import BsPagination from "../BsPagination";
import { Spinner } from "react-bootstrap";
import LoginCheck from "../General/LoginCheck";
import moment from "moment";
import { FaceFrownIcon } from "@heroicons/react/24/outline";

function CrewReward(props) {

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ROI/GetTeamROI',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setlistOfTransactions(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //// console.log(error);
            });



    }, [pageNo, pageSize]);


    const renderLedger = () => {

        if (listOfTransactions != null && listOfTransactions.length > 0) {
            return (
                listOfTransactions.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td data-label="Date"> {moment(item.Dated).format('DD/MM/YYYY')}</td>
                            <td data-label="Funding Amount">$ {item.TradingAmount}</td>
                            <td data-label="Eligible Funding Amount">$ {item.EligibleTradingAmount}</td>
                            <td data-label="Amount">$ {item.ROIAmount}</td>
                            <td data-label="Percentage">{item.ROIPercentage}%</td>
                            <td data-label="Status">{item.Status}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="4">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }
    return (
        <>
          
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Funding Amount</th>
                                <th>Reward Earned</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="4">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }
                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&

                        <BsPagination
                            length={listOfTransactions.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={(page) => setPageNo(page)}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default CrewReward;