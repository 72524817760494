import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { NumberFormat } from "../../Dashboard/General/Functions";
import TransactionMenu from "./TransactionMenu";
import BsPagination from "../BsPagination";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CoinTransactionsDetail = () => {


    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState('1');
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'CoinDetail/GetMemberCoins',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code === 1) {
                    setLedger(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);

                } else if (response.data.status_message === 'Invalid Access Key.') {
                    navigate('/login');
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });



    }, [pageNo, pageSize]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    let amount = 0;
                    if (item.Debit != 0) {
                        amount = item.Debit;
                    }
                    else {
                        amount = item.Credit;
                    }

                    return (
                        <tr key={index}>
                            <td data-label="Date"> {moment(item.Dated).format('DD/MM/YYYY')}</td>
                            <td data-label="Description">{item.Description}</td>

                            {
                                item.InQuantity > 0 &&
                                <td data-label="Quantity">{item.InQuantity.toFixed(8)}</td>
                            }
                           

                            {
                                item.OutQuantity > 0 &&
                                <td data-label="Quantity">{item.OutQuantity.toFixed(8)}</td>
                            }

                            <td data-label="BIC Coin Price">{item.BuyingSellingRate.toFixed(8)}</td>
                            <td data-label="Total Amount">${item.PurchaseAmount.toFixed(8)}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (

                <div className="else">
                    <FaceFrownIcon />
                    <h4>No Data Found</h4>
                </div>

            )
        }

    }

    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <ToastContainer />

            <div className="partner-table">
                <div className="table-wrap">
                    <table className="table">
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }


                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default CoinTransactionsDetail