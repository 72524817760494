import React, { useEffect, useRef } from 'react';
import { toast, ToastContainer } from "react-toastify";
import LoginCheck from "./General/LoginCheck";
import ReactTooltip from "react-tooltip";


const WidgetTradeView = (props) => {

  const container = useRef();

  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
            {
          "width": "100%",
          "height": "700",
              "autosize": false,
              "symbol": "FX:EURUSD",
              "timezone": "Etc/UTC",
              "theme": "dark",
              "style": "1",
              "locale": "en",
              "backgroundColor": "rgba(0, 0, 0, 1)",
              "gridColor": "rgba(255, 255, 255, 0.06)",
              "withdateranges": true,
              "range": "YTD",
              "hide_side_toolbar": false,
              "allow_symbol_change": true,
              "details": true,
              "hotlist": true,
              "calendar": false,
              "support_host": "https://www.tradingview.com"
            }`;
      container.current.appendChild(script);
    },
    []
  );

  const handelmassage = () => {
    toast.error('Coming soon in your region.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  }


  return (
    <>
      <LoginCheck />
      <ReactTooltip />
      <ToastContainer />
      <div className="tradingview-widget-container" ref={container} style={{ height: "200%", width: "100%" }}>
        <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      </div>
      <div className="button-row justify-content-center mt-5">
        <button className='button button-3d-outline' style={{ width: '46%' }}
          onClick={() => handelmassage()}
        >
          buy
        </button>
        <button
          onClick={() => handelmassage()}
          className='button button-3d-outline' style={{ width: '46%' }}
        >
          sell
        </button>

      </div>
    </>
  )
}

export default WidgetTradeView