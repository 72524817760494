import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {CheckIcon } from "@heroicons/react/24/outline";
const TransactionMenu = (props) => {

    const currentLocation = useLocation();
    const isMenuItemActive = (path) => {
        return currentLocation.pathname.startsWith(path);
    };

    const [show, setShow] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const ActiveMenu = urlParams.get('activemenu');

    const menuItems = [
        { path: '/dashboard/transactions/membership', label: 'Funds' },
        { path: '/dashboard/transactions/deposits', label: 'Refills' },
        { path: '/dashboard/transactions/funds-history/sent', label: 'Transfers' },
        { path: '/dashboard/transactions/profit-wallet/roi/self', label: 'Self Reward' },
        { path: '/dashboard/transactions/profit-wallet/roi/crew', label: 'Crew Reward' },
        { path: '/dashboard/transactions/roi/first', label: 'L1 Crew Reward' },
        { path: '/dashboard/transactions/roi/second', label: 'L2 Crew Reward' },
        { path: '/dashboard/transactions/roi/third', label: 'L3 Crew Reward' },
        { path: '/dashboard/transactions/level/one', label: 'L1 Direct Reward' },
        { path: '/dashboard/transactions/level/two', label: 'L2 Direct Reward' },
        { path: '/dashboard/transactions/level/three', label: 'L3 Direct Reward' },
        { path: '/dashboard/transactions/withdraw', label: 'Payout' },
        { path: '/dashboard/transactions/coin-transactions-detail', label: 'BIC Coins' },
        { path: '/dashboard/transactions/fee-deductions', label: 'Fee Deductions' },
    ];

    return (
        <>

            <div className="section-title mb-3 text-primary">Transactions</div>

            <div className="custom-dropdown">
                <div className="icon-input-box">
                    <div className="custom-input" data-label={ActiveMenu == null ? 'Funds' : ActiveMenu} onClick={() => setShow(!show)}>
                        <div className="icon">
                            {
                                show == true ?
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 -3.98606e-07L0 7.63251L1.43333 9L8 2.70318L14.5667 8.9682L16 7.60071L8 -3.98606e-07Z" fill="#fff" />
                                    </svg>

                                    :
                                    <svg width="16" height="9" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 14.15L0 2.15L2.15 0L12 9.9L21.85 0.05L24 2.2L12 14.15Z" fill="#fff" />
                                    </svg>
                            }
                        </div>
                    </div>
                </div>
                {
                    show == true &&
                    <div className="list-backgroud">
                        <ul className="menu-list">
                            {menuItems.map((item) => (
                                <li
                                    key={item.path}
                                    className={`nav-item ${isMenuItemActive(item.path) ? 'active' : ''}`}
                                    onClick={(e) => setShow(false)}
                                >
                                    <NavLink to={`${item.path}?activemenu=${item.label}`}>
                                        <span>{item.label}</span>
                                    </NavLink>
                                    <div className={`icon`}>
                                        {isMenuItemActive(item.path) ?
                                            <CheckIcon />
                                            :
                                            ''
                                        }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </div>


        </>
    )
}

export default TransactionMenu