import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Config from '../../Config';
import axios from 'axios';
import BICoin from '../../assets/images/coin.svg';
import { Spinner } from 'react-bootstrap';
import { BanknotesIcon, KeyIcon } from '@heroicons/react/24/outline';
import { NumberFormat6D } from './General/Functions';

const CoinSell = () => {
    const AccessKey = localStorage.getItem('AccessKey');
    const UserID = localStorage.getItem('ID');

    const [PinCode, setPinCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [MemberCoinBalance, setMemberCoinBalance] = useState('');
    const [SellingCoinRate, setSellingCoinRate] = useState(1);
    const [currencyAmount, setCurrencyAmount] = useState(0);
    const [coinAmount, setCoinAmount] = useState(0);
    const [error, setError] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const data = { AccessKey, UserID };

        const api_config = {
            method: 'post',
            url: `${Config.base_url}CoinDetail/GetSellingRate`,
            headers: { 'Content-Type': 'application/json' },
            data,
        };

        axios(api_config)
            .then((response) => {
                if (response.data.status_code === 1) {
                    setSellingCoinRate(response.data.SellingCoinRate);
                    setMemberCoinBalance(response.data.MemberCoinBalance);
                } else if (response.data.status_message == 'Invalid Access Key.') {
                    navigate('/login');
                } else {
                    toast.error(response.data.status_message);
                }
                setLoading(false);
            })
            .catch(() => {
                toast.error('Error fetching coin rate');
                setLoading(false);
            });
    }, []);

    const handleCurrencyChange = (e) => {
        const value = parseFloat(e.target.value);

        if (value >= 0 && value <= MemberCoinBalance) {
            setCurrencyAmount(value);
            setCoinAmount((value / SellingCoinRate).toFixed(2));
            setError(false);
        } else if (value > MemberCoinBalance) {
            toast.error('Amount cannot exceed your current balance', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            setError(false);
        }
        else {
            setCurrencyAmount('');
            setCoinAmount('');
            setError(true);
        }
    };

    const handleCoinChange = (e) => {
        const value = parseFloat(e.target.value);
        if (value >= 0) {
            const calculatedCurrency = (value * SellingCoinRate).toFixed(2);
            if (calculatedCurrency <= MemberCoinBalance) {
                setCoinAmount(value);
                setCurrencyAmount(calculatedCurrency);
                setError(false);
            } else {
                toast.error('Amount cannot exceed your current balance', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setError(false);
            }
        }
        else {
            setCurrencyAmount('');
            setCoinAmount('');
            setError(true);
        }
    };

    const handleCoinSell = () => {
        setLoading(true);
        const data = {
            AccessKey,
            UserID,
            Quantity: coinAmount,
            Amount: currencyAmount,
            PINCode: PinCode,
        };

        // console.log(data);

        const api_config = {
            method: 'post',
            url: `${Config.base_url}CoinDetail/SellCoin`,
            headers: { 'Content-Type': 'application/json' },
            data,
        };

        axios(api_config)
            .then((response) => {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    setCurrencyAmount(0);
                    setCoinAmount(0);
                    setPinCode('');
                    toast.success(response.data.status_message);
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/dashboard/dashboard');
                    }, 3000);
                } else if (response.data.status_message == 'Invalid Access Key.') {
                    navigate('/login');
                } else {
                    toast.error(response.data.status_message);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <h3 className="section-title">Sale Out BIC's</h3>
            <ToastContainer />
            {loading ? (
                <Spinner animation="border" role="status" className="full-screen-loading" />
            ) : (
                <section className="buy-coin form-default">
                    <div className="mobile-container">
                        <h1 className='mb-2'>Coin Selling Rate : <span>{NumberFormat6D(SellingCoinRate)}$ / BIC</span></h1>
                        <h1 className='mb-4'>Your Coin Balance :   <span>{NumberFormat6D(MemberCoinBalance)}</span></h1>

                        {/* Coin Input */}
                        <div className="wrap">
                            <div className="coin-wrap">
                                <label>No of Coin's</label>
                                <div className="icon-input">
                                    <input
                                        type="number"
                                        value={coinAmount === 0 ? '' : coinAmount}

                                        onChange={handleCoinChange}
                                        placeholder="No of Coin to Buy"
                                        className="form-control"
                                        required
                                    />
                                    <div className="icon">
                                        <img src={BICoin} alt="Coin Icon" style={{ width: '20px' }} />
                                    </div>
                                </div>
                            </div>

                            {/* Currency Input */}
                            <div className="coin-wrap">
                                <label>Amount</label>
                                <div className="icon-input">
                                    <input
                                        type="number"
                                        value={currencyAmount === 0 ? '' : currencyAmount}
                                        onChange={handleCurrencyChange}
                                        placeholder="Enter Amount to buy coin"
                                        className="form-control"
                                        required
                                    />
                                    <div className="icon">
                                        <BanknotesIcon />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="icon-input mb-3">
                            <input
                                type="text"
                                value={PinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                                placeholder="Enter Pin Code"
                                className="form-control"
                                required
                                maxLength={6}
                            />
                            <div className="icon">
                                <KeyIcon />
                            </div>
                        </div>

                        <button className="button button-3d-secondary w-100" disabled={PinCode.length < 1 || error} onClick={handleCoinSell}>
                            Sale Out
                        </button>
                    </div>
                </section>
            )}
        </>
    );
};

export default CoinSell;
